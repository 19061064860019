import Vue from 'vue'
import VueTabulator from 'vue-tabulator';
import App from './App.vue'

Vue.use(VueTabulator);
Vue.config.productionTip = false

new Vue({
  render: function (h) { return h(App) },
}).$mount('#app')
