<template>
  <div>
    <div>
      <flat-pickr
          v-model="start"
          :config="config"
          @on-change="dateChanged"
      >
      </flat-pickr>
      <flat-pickr
          v-model="finish"
          :config="config"
          @on-change="dateChanged"
      >
      </flat-pickr>
      <button @click="changeTable">Сменить таблицу</button>
      <button @click="changeSecondTableLeadByLeadStatus" v-if="secondTableCurrentColumnDate === 'created_at' && table === 'table2'">Данные по созданным сделкам</button>
      <button @click="changeSecondTableLeadByLeadStatus" v-if="secondTableCurrentColumnDate === 'closed_at' && table === 'table2'">Данные по закрытым сделкам</button>
      <button @click="exportXlsx">Export xlsx</button>
      <div v-if="table === 'table1'">
        <multiselect
            v-model="selectedManagers"
            :options="managers"
            :close-on-select="false"
            :multiple="true"
            placeholder="Выберите пользователей"
        >
        </multiselect>
        <div class="grid">
          <multiselect
              v-model="selectedChannels"
              :options="channels"
              :multiple="true"
              :close-on-select="false"
              label="name"
              group-values="channels"
              group-label="group"
              track-by="id"
              :group-select="true"
              placeholder="Выберите каналы"
          >
          </multiselect>
          <button @click="changeChannels">обновить</button>
        </div>
      </div>
    </div>
    <VueTabulator
        v-model="filteredData"
        :options="options"
        ref="tabulator"
    />
  </div>
</template>

<script>


import axios from "axios";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Russian} from "flatpickr/dist/l10n/ru.js"
import dayjs from 'dayjs'
import Multiselect from 'vue-multiselect'
import {groupBy, gup, headersForTableOne, headersForTableTwo} from "@/helpers";


export default {
  name: 'App',
  components: {flatPickr, Multiselect},
  data: () => ({
    baseUrl: 'https://mogo-api.pushka.biz',
    table: 'table1',
    secondTableCurrentColumnDate: 'created_at',
    channels: [],
    selectedChannels: [],
    selectedManagers: [],
    start: null,
    firstLoading: true,
    finish: null,
    config: {
      dateFormat: 'Y-m-d',
      locale: Russian,
    },
    data: [],
    options: {
      initialSort: [
        {column: "name", dir: "asc"},
      ],
      layout: "fitColumns",
      height: 'calc(100vh - 110px)',
      placeholder: 'Загрузка...',
      locale: 'ru-RU',
      autoColumns: false,
      layoutColumnsOnNewData: true,
      columns: [
        {name: '1', title: '', width: '100%'}
      ]
    }
  }),
  computed: {
    managers() {
      return this.data.map(r => r.name)
    },
    filteredData() {
      let data = this.data
      if (this.selectedManagers.length) {
        data = this.data.filter(r => this.selectedManagers.includes(r.name))
      }
      return data
    },
    transformedChannelsForRequest() {
      const channels = []
      if (this.selectedChannels.length) {
        this.selectedChannels.forEach(c => channels.push(c.id))
      } else {
        this.channels.forEach(g => {
          g.channels.forEach(c => channels.push(c.id))
        })
      }
      return channels

    }
  },
  methods: {
    async changeTable() {
      this.table = this.table === 'table1' ? 'table2' : 'table1'
      this.options.height = this.table === 'table1' ? 'calc(100vh - 110px)' : 'calc(100vh - 30px)'
      await this.updateTableData()
      this.getHeadersForTable()
    },
    exportXlsx() {
      const tabulatorInstance = this.$refs.tabulator.getInstance();
      tabulatorInstance.download("xlsx", `${this.table}.xlsx`, {sheetName: this.table});
    },
    async changeSecondTableLeadByLeadStatus() {
      this.secondTableCurrentColumnDate = this.secondTableCurrentColumnDate === 'created_at' ? 'closed_at' : 'created_at'
      await this.updateTableData()
    },
    async updateTableData() {
      this.data = []
      const params = {
        start: this.start,
        finish: this.finish,
        channels: this.transformedChannelsForRequest
      }
      if (this.table === 'table2') {
        params.columnDate = this.secondTableCurrentColumnDate
      }
      const r = await axios.get(`${this.baseUrl}/api/data/${this.table}`, {params})
      this.data = r.data
    },
    async changeChannels() {
      await this.updateTableData()
    },
    async dateChanged() {
      if (!this.firstLoading) {
        await this.updateTableData()
      }
    },
    getHeadersForTable() {
      this.options.columns = this.table === 'table1' ? headersForTableOne : headersForTableTwo
      this.options.columns.forEach(c => {
        c.sorter = c.sorter ? c.sorter : 'number'
        c.headerVertical = false
        c.hozAlign = c.hozAlign ? c.hozAlign : 'center'
        c.headerTooltip = c.title
        c.formatter = (cell) => cell.getValue() ? cell.getValue().toLocaleString() : null
      })
    },
    async getChannels() {
      const channels = (await axios.get(`${this.baseUrl}/api/channels`)).data
      groupBy(channels, 'group').forEach(g => {
        this.channels.push({
          group: g[0].group,
          channels: g
        })
      })
    }

  },
  async mounted() {
    this.baseUrl = gup('base_url') ? gup('base_url') : this.baseUrl
    const date = new Date();
    this.start = dayjs(new Date(date.getFullYear(), date.getMonth(), 1)).format('YYYY-MM-DD')
    this.finish = dayjs(new Date((date).getFullYear(), date.getMonth() + 1, 0)).format('YYYY-MM-DD')
    await this.getChannels()
    await this.updateTableData()
    this.firstLoading = false
    this.getHeadersForTable()
  }
}
</script>

<style lang="scss">
body, html {
  padding: 0;
  margin: 0;
  font-family: 'Nunito', sans-serif;
}

.grid {
  display: grid;
  grid-template-columns: 1fr max-content;
}

@import "~vue-multiselect/dist/vue-multiselect.min.css";
@import "~vue-tabulator/dist/scss/tabulator_site";
</style>
