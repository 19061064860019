export function groupBy(arr, prop) {
    const map = new Map(Array.from(arr, obj => [obj[prop], []]));
    arr.forEach(obj => map.get(obj[prop]).push(obj));
    return Array.from(map.values());
}

export function gup(name, url) {
    if (!url) url = location.href;
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    const regexS = "[\\?&]" + name + "=([^&#]*)";
    const regex = new RegExp(regexS);
    const results = regex.exec(url);
    return results == null ? null : results[1];
}

export const headersForTableOne = [
    {title: 'Менеджер', field: 'name', sorter: 'string', width: 220, hozAlign: 'left'},
    {
        title: 'Кол-во сделок в активном статусе на начало периода',
        field: 'activeStartLeads',
    },
    {
        title: 'Кол-во сделок в активном статусе на конец периода',
        field: 'activeFinishLeads',
    },
    {
        title: 'Кол-во новых сделок за период',
        field: 'createdLeads',
    },
    {
        title: 'Кол-во закрытых сделок за период',
        field: 'closedLeads',
    },
    {
        title: 'Среднее количество активных сделок на конец каждого дня за период',
        field: 'averageActiveLeads',
    },
    {
        title: 'Обновляемость фокуса (кол-во новых за период / среднее кол-во активных за период)',
        field: 'updateFocus',
    },
    {
        title: 'Кол-во продаж за период*',
        field: 'CSSale',
    },
    {
        title: 'Количество отгрузок за период*',
        field: 'CSShipment',
    },
    {
        title: 'Конверсия в продажу (по закрытым сделкам за период)',
        field: 'conversionSuccessLead',
    },
    {
        title: 'Конверсия в продажу (по среднему кол-ву активных сделок)',
        field: 'conversionSale',
    },
    {
        title: 'Кол-во продаж - продукт лизинг',
        field: 'successClosedLeadLeasing',
    },
    {
        title: 'Кол-во продаж - продукт лизбэк',
        field: 'successClosedLeadLeasingBack',
    },
    {
        title: 'Количество созаднных задач за период',
        field: 'createdTasks',
    },


]

export const headersForTableTwo = [
    {
        title: "Канал",
        field: "name",
        sorter: 'string', width: 220, hozAlign: 'left'
    },
    {
        title: "Количество лидов",
        field: "all",
    },
    {
        title: "Успешно реализовано",
        field: "status_142",
    },
    {
        title: "CR, %",
        field: "SR",
    },
    {
        title: "среднее количество дней реализованных",
        field: "avgSuccess",
    },
    {
        title: "среднее количество дней нереализованных",
        field: "avgFail",
    },
    {
        title: "Новый лид (неразобран)",
        field: "status_20876953",
    },
    {
        title: "Новый лид - принят в работу",
        field: "status_20881939",
    },
    {
        title: "Клиент проконсультирован. думает.",
        field: "status_20876956",
    },
    {
        title: "Клиент - ок. поиск авто",
        field: "status_20876959",
    },
    {
        title: "Авто выбран. назначена дата сделки",
        field: "status_20876962",
    },
    {
        title: "Закрыто и не реализовано",
        field: "status_143",
    },
    {
        title: "Закрыто и не реализовано (Слишком дорого)",
        field: "loss_reason_586011",
    },
    {
        title: "Закрыто и не реализовано (Выбрал банк/другую ЛК)",
        field: "loss_reason_586013",
    },
    {
        title: "Закрыто и не реализовано (Купил за свои)",
        field: "loss_reason_586015",
    },
    {
        title: "Закрыто и не реализовано (Передумал - без причины)",
        field: "loss_reason_586017",
    },
    {
        title: "Закрыто и не реализовано (Не устроил размер аванса)",
        field: "loss_reason_586019",
    },
    {
        title: "Закрыто и не реализовано (Передумал - отложил покупку)",
        field: "loss_reason_586021",
    },
    {
        title: "Закрыто и не реализовано (Отказ Мого - мошенники)",
        field: "loss_reason_586023",
    },
    {
        title: "Закрыто и не реализовано (Мусорный контакт)",
        field: "loss_reason_586025",
    },
    {
        title: "Закрыто и не реализовано (Недозвон)",
        field: "loss_reason_586027",
    },
    {
        title: "Закрыто и не реализовано (Не интересует возвратный)",
        field: "loss_reason_1161337",
    },
    {
        title: "Закрыто и не реализовано (Передумал - ушел в игнор)",
        field: "loss_reason_1309700",
    },
    {
        title: "Закрыто и не реализовано (Отказ Мого - другая причина)",
        field: "loss_reason_1310702",
    },
    {
        title: "Закрыто и не реализовано (Не может подтвердить доход)",
        field: "loss_reason_1325210",
    },
    {
        title: "Закрыто и не реализовано (Автоматический отказ)",
        field: "loss_reason_1329392",
    },


]